/**
 * @description 批量注册全局组件
 * @author Jeff.Guo
 */

import UploadFile from './UploadFile/UploadFile';
import NewUpload from './UploadFile/NewUpload';
// import demo from './Header/demo';
const component = {
    UploadFile,
    NewUpload
    // demo
};
// 批量注册组件
export default {
    install(Vue) {
        Object.keys(component).forEach((key) => {
            Vue.component(key, component[key]);
        });
    }
};