<!--
 * @Author: filename
 * @Description: 移动端-二次确认提示， 使用：this.$confirmTip.showTip('确认提交?').$on('confirm',() => {})
-->

<template>
    <div ref="dialog" class="dialog">
        <div class="content">
            <div class="dialog-header">提示</div>
            <div class="dialog-content">
                <div class="dialog-message">{{ content }}</div>
            </div>
            <div class="dialog-footer">
                <div @click="hanleCancel">取消</div>
                <div @click="hanleConfirm">确认</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        content: {
            type: String,
            default: ''
        },
        show: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        // 确认
        hanleConfirm() {
            this.$emit('confirm', '')
            this.$refs.dialog.remove()
        },
        // 取消
        hanleCancel() {
            this.$emit('cancel', '')
            this.$refs.dialog.remove()
        }
    }
}
</script>

<style lang="less" scoped>
.dialog {
    position: fixed;
    text-align: center;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    .content {
        position: fixed;
        top: 45%;
        left: 50%;
        width: 320px;
        overflow: hidden;
        font-size: 16px;
        background-color: #fff;
        border-radius: 16px;
        transform: translate3d(-50%, -50%, 0);
        transition: 0.3s;
        transition-property: transform, opacity, -webkit-transform;
    }
    .dialog-footer {
        border-top: 1px solid #f2f2f2;
        display: flex;
        overflow: hidden;
        user-select: none;
        > div {
            flex: 1;
            height: 48px;
            line-height: 48px;
            font-size: 16px;
            cursor: pointer;
        }
        > div:nth-child(1) {
            border-right: 1px solid #f2f2f2;
        }
        > div:nth-child(2) {
            color: red;
        }
    }
    .dialog-header {
        padding-top: 26px;
        font-weight: 500;
        line-height: 24px;
        text-align: center;
        font-size: 16px;
    }
    .dialog-message {
        flex: 1;
        max-height: 60vh;
        padding: 26px 24px;
        overflow-y: auto;
        font-size: 14px;
        line-height: 20px;
        white-space: pre-wrap;
        text-align: center;
        word-wrap: break-word;
        -webkit-overflow-scrolling: touch;
        padding-top: 8px;
        color: #646566;
    }
}
</style>