// 引入组件
import toast from "./MobileToast.vue"

// 定义插件对象
const ToastTip = {}
ToastTip.install = (Vue, options) => {
    const instance = Vue.extend(toast)
    let currentMsg
    const initInstance = () => {
        // 实例化vue实例
        currentMsg = new instance()
        let msgBoxEl = currentMsg.$mount().$el
        document.body.appendChild(msgBoxEl)
    }
    // 在Vue的原型上添加实例方法，以全局调用
    Vue.prototype.$toastTip = {
        // 成功
        success(options) {
            initInstance()
            currentMsg.icon = 'el-icon-check'
            currentMsg.content = options
            return currentMsg
        },
        // 成败
        fail(options) {
            initInstance()
            currentMsg.icon = 'el-icon-close'
            currentMsg.content = options
            return currentMsg
        },
        // 自定义
        message(options) {
            initInstance()
            if(typeof options === 'string') {
                currentMsg.content = options
            } else if (typeof options === 'object') {
                Object.assign(currentMsg, options)
            }
            return currentMsg // 为了链式调用
        }
    }
}

export default ToastTip