/*
 * @公共方法库
 * @author: Jeff Guo
 */
import store from '../store'
import cryptoAES from './cryptoAES'
import { getTempUrl, multipleReport } from '../api/workfow'
import { MD5 } from 'crypto-js'
import {FileService}  from '@galaxy-fe/utils/dist/index'
const fileService = new FileService({
    env: process.env.VUE_APP_ENV==='production'?'prod':'test',
    businessCode: '4002002', // 业务code
})//cookie操作类
export const Cookie = {

    del: (name, path, domain) => {
        document.cookie = name + "=" + ((path) ? "; path=" + path : "") + ((domain) ? "; domain=" + domain : "") + "; expires=Thu, 01-Jan-70 00:00:01 GMT";
    },

    get: (name) => {
        var v = document.cookie.match('(?:^|;)\\s*' + name + '=([^;]*)');
        return v ? decodeURIComponent(v[1]) : null;
    },

    set: (name, value, expires, path, domain) => {
        var str = name + "=" + encodeURIComponent(value);
        if (expires != null || expires != '') {
            if (expires == 0) {
                expires = 100 * 365 * 24 * 60;
            }
            var exp = new Date();
            exp.setTime(exp.getTime() + expires * 60 * 1000);
            str += "; expires=" + exp.toGMTString();
        }
        if (path) {
            str += "; path=" + path;
        }
        if (domain) {
            str += "; domain=" + domain;
        }
        document.cookie = str;
    }
};

//日期类操作方法
export const date = {

    /**
     * 日期格式化
     * @param date(String or Date)
     * @param format
     * @returns {format}
     */
    format: (date, format) => {
        if (typeof date === 'string') {
            date = new Date(Date.parse(date.replace(/-\/./g, "/")));
        } else if (typeof date === 'number') {
            date = new Date(date);
        }
        let newFormat = format || 'yyyy-MM-dd',
            o = {
                "M+": date.getMonth() + 1, //月份
                "d+": date.getDate(), //日
                "h+": date.getHours(), //小时
                "m+": date.getMinutes(), //分
                "s+": date.getSeconds(), //秒
                "q+": Math.floor((date.getMonth() + 3) / 3), //季度
                "S": date.getMilliseconds() //毫秒
            };
        if (/(y+)/.test(newFormat)) {
            newFormat = newFormat.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
        }
        for (let k in o) {
            if (new RegExp("(" + k + ")").test(newFormat)) {
                newFormat = newFormat.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
            }
        }
        return newFormat;
    }

};

/*
 *随机类
 */
export const random = {
    intNum: (begin, end) => {
        var c = end - begin + 1;
        return Math.floor(Math.random() * c + begin);
    },
    //生成指定位数的随机字符串大小写字母
    strings: (len) => {
        len = len || 32;
        var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz',
            max = chars.length,
            exp = '';
        for (let i = 0; i < len; i++) {
            exp += chars.charAt(Math.floor(Math.random() * (max + 1)));
        }
        return exp;
    }
};

export const verify = {
    /**
     * @param val 登录名  登录名由6-10位之间的字母、下划线、@以及数字组成
     * @returns 验证成功返回true，否则返回flase
     */
    isLoginName(val) {
        let reg = /^[a-zA-Z0-9_@]{5,10}$/;
        return commonVerify(val, reg);
    },

}
export const common = {
    /**
     * 从当前数据对象上克隆一份数据
     * (深拷贝)
     * @param data 源数据
     */
    CloneObj(data) {
        return JSON.parse(JSON.stringify(data));
    }
}

/**
 * @param val 传入的值
 * @param reg 验证规则
 */
function commonVerify(val, reg) {
    return val && val.length > 0 && reg.test(val) ? true : false;
}

/**
 * @description 图片预览
 * @param { fileName } 文件名
 * @author Jason
 * @example this.$utils.preview('https://sdsds.com/a.jpg')
 */
export const preview = (fileName) => window.open(toOss(fileName));

/**
 * @description 表单重置
 * @author Jason
 * @param { refName } 节点名
 * @example this.$utils.resetForm(refName)
 */
export const resetForm = (refName) => {
    if (this.$refs[refName]) {
        this.$refs[refName].resetFields();
    }
}

// 判断是否是图片
export const isImage = (url) => {
    return /(jpg|png|gif|jpeg)/i.test(url);
}

/**
 * @description 导出文件
 * @author Jeff.Guo
 * @param { data } 接口请求过来的二进制文件
 * @param { fileName } 自定义下载保存的文件名
 * @example this.$utils.exportData(res, new Date()*1 + '.docx')
 */
export const exportData = (data, fileName) => {
    let link = document.createElement('a');
    link.download = fileName.replace(/\?.*/g,'');
    link.style.display = 'none';
    let blob = new Blob([data]);
    link.href = URL.createObjectURL(blob);
    document.body.appendChild(link);
    link.click();
    link.remove();
}

/**
 * @description 下载在线文件
 * @author Akko
 * @param { data } 接口请求过来的在线下载地址
 * @param { fileName } 自定义下载保存的文件名
 * @example this.$utils.downLoadData(res,fileName)
 */
export const downLoadData = (data, fileName) => {
    let link = document.createElement("a");
    link.href = toOss(data);
    link.download = fileName;
    link.click();
    link.remove();
}

/*
 * 获取当天时间
 */
export const today = () => {
    let now = new Date();
    let year = now.getFullYear();
    let month = now.getMonth();
    let date = now.getDate();
    month = month + 1;
    if (month < 10) month = "0" + month;
    if (date < 10) date = "0" + date;
    let today = year + "-" + month + "-" + date;
    console.log(today)
    return today
}
// 生成onlyoffice预览url 提示：除图片格式的文件  data扩展参数
export const previewUrl = async (fileUrl, fileName, data = {}) => {
    const index = fileUrl.lastIndexOf('.')
    const fileType = fileUrl.substr(index + 1)
    const res = await getToOssUrl(fileUrl)
    const query = {
        userName: store.state.user.userName,
        fileName: fileName || fileUrl.substr((fileUrl.lastIndexOf('/') + 1)),
        fileUrl: res,
        fileType: fileType,
        ...data
    }
    const i_o = encodeURIComponent(cryptoAES.encrypt(encodeURIComponent(JSON.stringify(query))))
    return `${process.env.VUE_APP_DOC_URL}/CheckDoc?i_o=${i_o}`
}
// 新的接口获取oss地址
export const getToOssUrl = async (url) => {
    let res = await fileService.getOssFilePublicUrlAsync(url)
    return res
}
// 判断是否为图片
export const isImageType = (str) => {
	// toLowerCase() 将字符串转换为小写，返回一个新的字符串
	return ['png', 'jpg','jpeg', 'bmp', 'gif', 'webp', 'psd', 'svg', 'tiff'].indexOf(str.toLowerCase()) !== -1
}

/*
 * 判断是否是移动设备
 */
export const isMobile = () => {
    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    return flag
}

/**
 * @description url 下载
 * @author zhai
 * @example this.$utils.exportData(res, new Date()*1 + '.docx')
 */
 export const downloadRes = async (url, fileName) => {
    let response = await fetch(url); // 内容转变成blob地址
    let blob = await response.blob();  // 创建隐藏的可下载链接
    let objectUrl = window.URL.createObjectURL(blob);
    let a = document.createElement('a');
    a.href = objectUrl;
    a.download = fileName;
    a.click()
    a.remove();
}

/**
 * @description 判断是否是heic格式图片
 * @author filename
 * @example header = (89504e47:'image/png'),(47494638:'image/gif'),（ffd8ffDB,ffd8ffe0,ffd8ffe1,ffd8ffe2,ffd8ffe3,ffd8ffe8: 'image/jpeg'),(000: 'image/jpeg')
 */
export const isHeic = (reader) => {
    const bufferInt = new Uint8Array(reader.result);
    const arr = bufferInt.slice(0, 4);  // 通用格式图片
    const headerArr = bufferInt.slice(0, 16);  // heic格式图片
    let header = '';
    let allHeader = '';
    for (let i = 0; i < arr.length; i++) {
        header += arr[i].toString(16); // 转成16进制的buffer
    }
    for (let i = 0; i < headerArr.length; i++) {
        allHeader += headerArr[i].toString(16);
    }
    if (header.indexOf('000') !== -1) { // heic开头可能是00020,00018,00028, 判断000开头的为heic格式就行
        return (allHeader.lastIndexOf('68656963') === 13 || allHeader.lastIndexOf('68656966') === 13)
    }
    return false
}

// 文件地址上报
export const fileReport =(url) => {
    const file_report_img = new Image()
    file_report_img.src = `${process.env.VUE_APP_CRM_URL}/business/common-logic/upload-report?url=${url}`
}

// 多文件上报
export const fileMultipleReport = async (urls) => {
    return await multipleReport({ urls })
}

// 生存环境神策埋点
export const sensorsData = (vue) => {
    // 埋点
    const sensors = require('sa-sdk-javascript')
    sensors.init({
        server_url: `https://importsensors.galaxy-immi.com/sa?project=${process.env.VUE_APP_ENV === 'production' ? 'default_1' : 'default' }`,
        is_track_single_page: true, // 单页面配置，默认开启，若页面中有锚点设计，需要将该配置删除，否则触发锚点会多触发 $pageview 事件
        use_client_time: true,
        send_type:'beacon',
        // show_log: true, // 调试模式可以控制台看埋点数据
        heatmap: {
            //是否开启点击图，default 表示开启，自动采集 $WebClick 事件，可以设置 'not_collect' 表示关闭。
            clickmap:'default',
            //是否开启触达图，not_collect 表示关闭，不会自动采集 $WebStay 事件，可以设置 'default' 表示开启。
            scroll_notice_map:'default'
        }
    })
    // 注册公共属性
    sensors.registerPage({
        platform_type: 'web', // 平台类型
        system_version: '3.0', // 系统版本
        system_mark: 'Galaxy_CRM_Client', // 系统标识
    })
    // 判断用户是否已登录，已登录则把用户id传入神策
    const user = JSON.parse(localStorage.getItem('user'))
    if (user && Object.keys(user).length > 0) {
        sensors.bind("identity_client_user_id", user.userId + "")
        sensors.bind("$identity_mobile", user.phone + "")
    }
    sensors.quick('autoTrack'); //用于采集 $pageview 事件
    sensors.use('PageLeave', {}) // 采集 Web 页面浏览时长
    sensors.use('PageLoad', {}); // 采集 Web 页面加载时长
    // 挂载sensors到vue实例上
    vue.prototype.$sensors = sensors
}

// OSS安全跳转链接,加密算法
// export const toOss = (url) => {
//     if (!url) return;
//     if (url.indexOf('crm.galaxy-immi.com') > -1) return url;
//     if (url.indexOf('galaxy-immi') < 0 && url.indexOf('aliyuncs.com') < 0) return url;
//     let galaxy = '',
//         env = process.env.VUE_APP_ENV === 'production' ? 'https://serversitemicros.galaxy-immi.com' : process.env.VUE_APP_SERVERSITEMICROS,
//         path = 'business/common-logic/temp-url',
//         baseUrl = `${env}/${path}?c=`,
//         user = JSON.parse(localStorage.getItem('user'));
//     if (!user) {
//         router.push({
//             path: "/login"
//         });
//     }
//     for (let i = 0; i < decodeURIComponent(url.length); i++) {
//         galaxy += "galaxy";
//     }
//     return `${baseUrl}${url}&a=${user.id || '-1'}&b=${cryptoAES.MD5(galaxy + (user.id || '-1')).toString()}`;
// }

export const toOss = (url)=>{
    const user = JSON.parse(localStorage.getItem('user'));
    if (!user) { // 登录失效
        router.push({
            path: "/login"
        });
    }
    return fileService.getOssFilePublicUrlSync(url)
}

export const multipartUpload = (file,isPublic=false)=>{
    return isPublic === true ? fileService.publicUploadFile(file) : fileService.privateUploadFile(file)
}

/**
 * 构建查询字符串
 * @param {Object} params - 包含参数的对象
 * @param {Array} selectedParams - 要拼接的特定参数的数组
 * @returns {string} - 拼接后的查询字符串
 */
function buildQueryString(params, selectedParams) {
    let queryString = ""    // 遍历选定的参数数组
    for (const key of selectedParams) {
        // 检查参数是否存在于原始对象中并且具有值
        if (params.hasOwnProperty(key) && params[key]!==undefined) {
        // 如果queryString已经有内容，就在前面加上&
        if (queryString) {
            queryString += "&"
        }
        // 将选定的参数拼接到字符串中
        queryString += `${key}=${params[key]}`
        }
    }

    return queryString
}

/**
 * 拼接签名sign
 * @param {Object} params - 参数
 */
export function signParamsString(params) {
    // 要拼接的特定参数的数组
    const selectedParams= ["id","orderId","order_id","user_id","userId","order_sn","phoneNum"]
    const signString = buildQueryString({...params}, selectedParams)
    return MD5(signString).toString()
}

