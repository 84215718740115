/**
 * @description 全局弹出placeholder指令
 * @author AKKO 2021.10.13
 * @example <el-input type="text" v-showp  placeholder="请输入" />
 */

const vshowp = {
    bind(el, binding, vnode) {
        console.log(vnode)
        el.handler = () => {
            let keep = document.getElementById("Galaxy876");
            if (keep) {
                keep.parentNode.removeChild(keep);
                el.removeEventListener('focus', el.handler);
            }
            let div = document.createElement('div'),
            offsetLeft=vnode.context.$el.offsetLeft,
            offsetTop=vnode.context.$el.offsetTop;
            div.id = 'Galaxy876';
            div.readOnly = 'readonly';
            div.style.position = 'absolute';
            div.style.left = (offsetLeft-100)+'px';
            div.style.top = (offsetTop+6)+'px';
            div.innerText = vnode.data.attrs.placeholder;
            document.body.appendChild(div);
        };
        el.addEventListener('focus', el.handler, true);
    },
    unbind(el) {
        el.removeEventListener('focus', el.handler);
    },
};
export default vshowp