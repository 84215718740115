// sentry接入配置
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';

export const initSentry = (vueInstance, routerInstance) => {
    Sentry.init({
        Vue: vueInstance,
        dsn: "https://d6f5b7c969f44dd2b9b192326a3f0d56@dev-sentry.galaxy-immi.com/8",
        integrations: [
            new Integrations.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(routerInstance),
                tracingOrigins: ["localhost", "my-site-url.com", /^\//],
            }),
        ],
        environment: process.env.VUE_APP_ENV,
        release: `${APP_VERSION}`,
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
};
