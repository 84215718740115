/*
 * @description  公共表单校验
 * @author: Jeff Guo
 * @example
 *      rules: {
            myPhone: [
                {
                    required: true, validator: this.$rules.validate.phone, trigger: 'blur'
                }
            ]
        }
 */

// 手机号
let phone = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
// 邮箱
let email = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/;
// 身份证
let idCard = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
// 档案号规则
let fileNum = /^[0-9A-Z-]{15}$/
let validate = (function () {
    return {

        // 邮箱验证规则
        email(rule, value, cb) {
            if (!value) {
                return cb(new Error('邮箱不能为空'));
            }
            if (!email.test(value)) {
                return cb(new Error('邮箱格式不正确'));
            }
            cb();
        },

        // 手机号验证规则
        phone(rule, value, cb) {
            if (!value) {
                return cb(new Error('手机号不能为空！'));
            }
            if (!phone.test(value)) {
                return cb(new Error('请输入正确的手机号码'));
            }
            cb();
        },

        // 身份证号验证规则
        idCard(rule, value, cb) {
            if (!value) {
                return cb(new Error('身份证不能为空！'));
            }
            if (!idCard.test(value)) {
                return cb(new Error('请输入正确的身份证号码'));
            }
            cb();
        },

        // 数字+大写字母
        fileNum(rule, value, cb) {
            if (value) {
                if (!fileNum.test(value)) {
                    return cb(new Error('合同编号格式为4位大写字母+9数字组成！'));
                }
            }
            cb();
        }
    }

}())
exports.validate = validate
