import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import Page404 from "../views/Page404.vue";
import store from '@/store/index.js';
import { getOrderStatus } from "@/api/workfow.js";

Vue.use(VueRouter);

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
}

const routes = [
    // 登录
    {
        path: "/Login",
        name: "Login",
        component: Login,
        hidden: true
    },
    // 404
    {
        path: "*",
        name: "404",
        component: Page404,
        hidden: true
    },
    {
        path: "/",
        name: "Layout",
        component: () =>
            import ("@/views/Layout/Layout.vue"),
        children: [
            // 项目列表
            {
                path: "/ProjectList",
                name: "ProjectList",
                component: () =>
                    import ("@/views/ProjectList/ProjectList.vue")
            },
            // 项目进度
            {
                path: "/HandleProgress",
                name: "HandleProgress",
                component: () =>
                    import ("@/views/HandleProgress.vue")
            },
            // 服务端跳转接收页
            {
                path: "/Auth/:key",
                name: "Auth",
                component: () =>
                    import ("@/views/Auth.vue")
            },
            // 上传资料
            {
                path: "/UploadData",
                name: "UploadData",
                component: () =>
                    import ("@/views/UploadData/UploadData.vue")
            },
            // 文书稿件
            {
                path: "/Manuscript",
                name: "Manuscript",
                component: () =>
                    import ("@/views/Manuscript/Manuscript.vue")
            },
            // 文书稿件 - 移动端
            {
                path: "/MobileManuscript",
                name: "MobileManuscript",
                component: () =>
                    import ("@/views/Mobile/Manuscript/Manuscript.vue")
            },
            // 项目列表 - 移动端
            {
                path: "/MobileProjectList",
                name: "MobileProjectList",
                component: () =>
                    import ("@/views/Mobile/ProjectList/ProjectList.vue")
            },
            // 文档onlyoffice - 移动端
            {
                path: "/MobileWriting",
                name: "MobileWriting",
                component: () =>
                    import ("@/views/Mobile/Writing/Writing.vue")
            },
            // 邮寄原件
            {
                path: "/PostFile",
                name: "PostFile",
                component: () =>
                    import ("@/views/PostFile/PostFile.vue")
            },
            // 个人信息表
            {
                path: "/WritInfo",
                name: "WritInfo",
                component: () =>
                    import ("@/views/WritInfo/index.vue")
            },
            // 文书信息
            {
                path: "/CommonInfo",
                name: "CommonInfo",
                component: () =>
                    import ("@/views/CommonInfo/CommonInfo.vue")
            },
            //  信息表7步骤
            {
                path: "/UserInfo",
                name: "UserInfo",
                component: () =>
                    import ("@/views/UserInfo/UserInfo.vue"),
                children: [
                    // 优才
                    {
                        path: "User/:id",
                        name: "step1",
                        component: () =>
                            import ("@/views/UserInfo/Gifted/User/User.vue"),
                        meta: {
                            product: "1",
                            step: 1,
                            title: '申请人信息'
                        }
                    },
                    {
                        path: "Parent/:id",
                        name: "step2",
                        component: () =>
                            import ("@/views/UserInfo/Gifted/Parent/Parent.vue"),
                        meta: {
                            product: "1",
                            step: 2,
                            title: '家庭成员信息'
                        }
                    },
                    {
                        path: "Basics/:id",
                        name: "step3",
                        component: () =>
                            import ("@/views/UserInfo/Gifted/Basics/Basics.vue"),
                        meta: {
                            product: "1",
                            step: 3,
                            title: '基本资料'
                        }
                    },
                    {
                        path: "Qualification/:id",
                        name: "step4",
                        component: () =>
                            import ("@/views/UserInfo/Gifted/Qualification/Qualification.vue"),
                        meta: {
                            product: "1",
                            step: 4,
                            title: '学历/专业资格'
                        }
                    },
                    {
                        path: "Work/:id",
                        name: "step5",
                        component: () =>
                            import ("@/views/UserInfo/Gifted/Work/Work.vue"),
                        meta: {
                            product: "1",
                            step: 5,
                            title: '工作经验'
                        }
                    },
                    {
                        path: "Other/:id",
                        name: "step6",
                        component: () =>
                            import ("@/views/UserInfo/Gifted/Other/Other.vue"),
                        meta: {
                            product: "1",
                            step: 6,
                            title: '其他信息'
                        }
                    },
                    {
                        path: "Done/:id",
                        name: "step7",
                        component: () =>
                            import ("@/views/UserInfo/Gifted/Done/Done.vue"),
                        meta: {
                            product: "1",
                            step: 7,
                            title: '完成'
                        }
                    },
                    // 专才
                    {
                        path: "Usere/:id",
                        name: "etep1",
                        component: () =>
                            import ("@/views/UserInfo/Expert/User/User.vue"),
                        meta: {
                            product: "2",
                            step: 1,
                            title: '申请人信息'
                        }
                    },
                    {
                        path: "Parente/:id",
                        name: "etep2",
                        component: () =>
                            import ("@/views/UserInfo/Expert/Parent/Parent.vue"),
                        meta: {
                            product: "2",
                            step: 2,
                            title: '家庭成员信息'
                        }
                    },
                    {
                        path: "Basicse/:id",
                        name: "etep3",
                        component: () =>
                            import ("@/views/UserInfo/Expert/Basics/Basics.vue"),
                        meta: {
                            product: "2",
                            step: 3,
                            title: '基本资格'
                        }
                    },
                    {
                        path: "Qualificatione/:id",
                        name: "etep4",
                        component: () =>
                            import ("@/views/UserInfo/Expert/Qualification/Qualification.vue"),
                        meta: {
                            product: "2",
                            step: 4,
                            title: '学历/专业资格'
                        }
                    },
                    {
                        path: "Worke/:id",
                        name: "etep5",
                        component: () =>
                            import ("@/views/UserInfo/Expert/Work/Work.vue"),
                        meta: {
                            product: "2",
                            step: 5,
                            title: '就业/雇主信息'
                        }
                    },
                    {
                        path: "Othere/:id",
                        name: "etep6",
                        component: () =>
                            import ("@/views/UserInfo/Expert/Other/Other.vue"),
                        meta: {
                            product: "2",
                            step: 6,
                            title: '受聘人员职位详情'
                        }
                    },
                    {
                        path: "Donee/:id",
                        name: "etep7",
                        component: () =>
                            import ("@/views/UserInfo/Expert/Done/Done.vue"),
                        meta: {
                            product: "2",
                            step: 7,
                            title: '完成'
                        }
                    },
                    // 留学
                    {
                        path: "Userl/:id",
                        name: "ltep1",
                        component: () =>
                            import ("@/views/UserInfo/Study/User/User.vue"),
                        meta: {
                            product: "3",
                            step: 1,
                            title: '申请人信息'
                        }
                    },
                    {
                        path: "Parentl/:id",
                        name: "ltep2",
                        component: () =>
                            import ("@/views/UserInfo/Study/Parent/Parent.vue"),
                        meta: {
                            product: "3",
                            step: 2,
                            title: '家庭成员信息'
                        }
                    },
                    {
                        path: "Basicsl/:id",
                        name: "ltep3",
                        component: () =>
                            import ("@/views/UserInfo/Study/Basics/Basics.vue"),
                        meta: {
                            product: "3",
                            step: 3,
                            title: '基本资料'
                        }
                    },
                    {
                        path: "Qualificationl/:id",
                        name: "ltep4",
                        component: () =>
                            import ("@/views/UserInfo/Study/Qualification/Qualification.vue"),
                        meta: {
                            product: "3",
                            step: 4,
                            title: '学历/专业资格'
                        }
                    },
                    {
                        path: "Workl/:id",
                        name: "ltep5",
                        component: () =>
                            import ("@/views/UserInfo/Study/Work/Work.vue"),
                        meta: {
                            product: "3",
                            step: 5,
                            title: '工作经验'
                        }
                    },
                    // {
                    //     path: "Otherl/:id",
                    //     name: "ltep6",
                    //     component: () =>
                    //         import ("@/views/UserInfo/Study/Other/Other.vue"),
                    //     meta: {
                    //         product: "3",
                    //         step: 6,
                    //         title: '主观性评价'
                    //     }
                    // },
                    {
                        path: "Donel/:id",
                        name: "ltep6",
                        component: () =>
                            import ("@/views/UserInfo/Study/Done/Done.vue"),
                        meta: {
                            product: "3",
                            step: 7,
                            title: '完成'
                        }
                    },
                    // 优才小项目
                    {
                        path: "Userp/:id",
                        name: "ptep1",
                        component: () =>
                            import ("@/views/UserInfo/SmallGifted/User/User.vue"),
                        meta: {
                            product: "4",
                            step: 1,
                            title: '申请人信息'
                        }
                    },
                    // 二曲教育
                    {
                        path: "education/:id",
                        name: "education1",
                        component: () =>
                            import ("@/views/UserInfo/Study/flowInfo/index.vue"),
                        meta: {
                            product: "4",
                            step: 1,
                            title: '申请人信息'
                        }
                    },
                    {
                        path: "Parentp/:id",
                        name: "ptep2",
                        component: () =>
                            import ("@/views/UserInfo/SmallGifted/Parent/Parent.vue"),
                        meta: {
                            product: "4",
                            step: 2,
                            title: '家庭成员信息'
                        }
                    },
                    {
                        path: "Qualificationp/:id",
                        name: "ptep3",
                        component: () =>
                            import ("@/views/UserInfo/SmallGifted/Qualification/Qualification.vue"),
                        meta: {
                            product: "4",
                            step: 3,
                            title: '学历/专业资格'
                        }
                    },
                    {
                        path: "Workp/:id",
                        name: "ptep4",
                        component: () =>
                            import ("@/views/UserInfo/SmallGifted/Work/Work.vue"),
                        meta: {
                            product: "4",
                            step: 4,
                            title: '工作经验'
                        }
                    },
                    {
                        path: "Donep/:id",
                        name: "ptep5",
                        component: () =>
                            import ("@/views/UserInfo/SmallGifted/Done/Done.vue"),
                        meta: {
                            product: "4",
                            step: 5,
                            title: '完成'
                        }
                    },
                    // 新小项目优才（面试到永居后续服务、办理身份证、续签到永居、续签服务【单次】、香港永居服务）路由
                    {
                        path: "SmallUser/:id",
                        name: "SmallStep1",
                        component: () => import("@/views/UserInfo/NewSmallGifted/User/User.vue"),
                        meta: {
                            product: "4",
                            step: 1,
                            title: '主申请人信息 '
                        }
                    },
                    {
                        path: "SmallFamily/:id",
                        name: "SmallStep2",
                        component: () => import("@/views/UserInfo/NewSmallGifted/Family/Family.vue"),
                        meta: {
                            product: "4",
                            step: 2,
                            title: '受养人信息'
                        }
                    },
                    {
                        path: "SmallDone/:id",
                        name: "SmallStep3",
                        component: () => import("@/views/UserInfo/NewSmallGifted/Done/Done.vue"),
                        meta: {
                            product: "4",
                            step: 5,
                            title: '完成'
                        }
                    },
                ]
            },
            // 其他页面
            {
                path: "/JumpPage",
                name: "JumpPage",
                component: () =>
                    import ("@/views/Other/JumpPage.vue")
            },
            // 服务协议
            {
                path: "/Agreement",
                name: "Agreement",
                component: () =>
                    import ("@/views/Agreement/Agreement.vue")
            }
        ]
    },

];

const router = new VueRouter({
    mode: "hash",
    base: process.env.BASE_URL,
    scrollBehavior: () => ({
        y: 0
    }),
    routes
});
export default router;

//newRoute放置全部的路由name,包括UserInfo的子路由
let userInfoRoute = routes[2].children;
// [{path: "/Login", name: "Login"},{path: "*", name: "404"}];
let uR = [];
let cR = [];
userInfoRoute.map((item, index) => {
    if (item.name != 'UserInfo') {
        return uR.push({
            path: item.path,
            name: item.name
        })
    } else if (item.name == 'UserInfo') {
        return cR = item.children;
    }
})
let childR = [];
cR.map((item, index) => {
    return childR.push({
        path: "/UserInfo/" + item.path,
        name: item.name
    })
})
let newRoute = [...uR, ...childR];
/**
 * 创建查询订单的状态的处理函数
 * @returns (orderId) => Promise
 */
function createQueryOrderStatusHandler() {
    let cache = new Map()
    return function(orderId) {
        return new Promise((resolve, reject) => {
            if (cache.has(orderId)) {
                console.log('该订单状态已缓存', orderId, cache.get(orderId))
                resolve(cache.get(orderId))
            } else {
                return getOrderStatus({ orderId }).then(res => {
                    // 状态：0是进行中 1是退款中 2已关闭 3暂停中
                    const status = res.data
                    cache.set(orderId, status)
                    resolve(status)
                }).catch(err => {
                    reject(err)
                })
            }
        })
    }
}
// 查询订单状态的处理函数
const queryOrderStatusHandler = createQueryOrderStatusHandler()
    //路由拦截
router.beforeEach((to, from, next) => {
    if (to.name != 'Login' && to.name !== 'Auth') {
        if (!localStorage.getItem('token')) {
            // 没有token
            return next({
                path: '/Login'
            })
        } else {
            switch (to.name) {
                case 'HandleProgress':
                case 'CommonInfo':
                case 'WritInfo':
                case 'UploadData':
                case 'Manuscript':
                case 'PostFile':
                    // 先异步判断订单状态来决定跳转逻辑
                    return new Promise(() => {
                        store.commit('user');
                        queryOrderStatusHandler(store.state.user.orderId).then(status => {
                            if (status !== 0) {
                                console.log('该订单状态不是进行中，回到产品列表页面')
                                    // 订单状态不是0，这些页面都不能查看，跳转到产品列表页
                                next({
                                    path: '/ProjectList'
                                });
                            } else {
                                next();
                            }
                        }).catch(err => {
                            console.error('获取订单状态失败，跳转取消', err)
                                // 订单状态获取失败，跳转取消
                            next(false);
                        })
                    });
                default:
                    next()
                    break;
            }
        }
    }
    next();

})
