// 引入组件
import dialog from "./MobileDialog.vue"

// 定义插件对象
const ConfirmTip = {}
ConfirmTip.install = (Vue, options) => {
    const instance = Vue.extend(dialog)
    let currentMsg
    const initInstance = () => {
        // 实例化vue实例
        currentMsg = new instance()
        let msgBoxEl = currentMsg.$mount().$el
        document.body.appendChild(msgBoxEl)
    }
    // 在Vue的原型上添加实例方法，以全局调用
    Vue.prototype.$confirmTip = {
        showTip(options) {
            initInstance()
            if(typeof options === 'string') {
                currentMsg.content = options
            } else if (typeof options === 'object') {
                Object.assign(currentMsg, options)
            }
            return currentMsg; // 为了链式调用
        }
    }
}

export default ConfirmTip