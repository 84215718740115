<!--
* @name：视频直传OSS组件
* @author：zhai
demo <UploadFile :file-list="fileList1" file-list-name="name" file-list-url="path" :is-show-file="false" :limit="1" :file-type="[]" :format-tip="['png', 'jpeg', 'jpg', 'm4a', 'mp3']" @fileList="onChangeFile1" />
-->
<template>
    <div class="upload-file-box">
        <div class="upload-file-content">
            <div class="upload-file-button-box">
                <el-upload
                    ref="upload"
                    class="upload-video"
                    action="/"
                    name="name"
                    :accept="fileType.join(',')"
                    :on-remove="remove"
                    :limit="limit"
                    multiple
                    :before-upload="beforeAvatarUpload"
                    :http-request="upLoad"
                    :auto-upload="autoUpload"
                    :disabled="isUpload"
                    :on-exceed="handleExceed"
                >
                    <el-button
                        slot="trigger"
                        :disabled="isUpload"
                        size="small"
                        :type="disType"
                        :loading="isLoading.type === 1 ? isLoading.loading : loading"
                        :style="{fontSize: fontSize + 'px'}"
                    >{{ buttonText }}</el-button>
                    <el-button
                        v-if="!autoUpload"
                        style="margin-left: 10px;"
                        size="small"
                        type="success"
                        :loading="loading"
                        @click="$refs.upload.submit()"
                    >上传到服务器</el-button>
                    <span slot="tip" class="tips">{{ tips }}</span>
                </el-upload>
                <div v-if="isShowName">{{ name }}</div>
            </div>

            <ul v-if="isShowFile && uploadFileList.length" class="upload-file-list">
                <li v-for="(item, index) in uploadFileList" :key="index" class="upload-file-list-li">
                    <span class="upload-file-list-name" @click="onPreview(item,index)">{{ item.fileName }}</span>
                    <img v-if="limit !== 1" src="@/assets/img/3.png" @click="onDelete(item, index)" />
                </li>
            </ul>
        </div>

        <span v-if="placeholder" style="color:#ccc">{{ placeholder }}</span>
    </div>
</template>

<script>
export default {
    name: 'UploadFiles',
    props: {
        value: {
            type: String,
            default: ''
        },
        /*
        设置OSS文件ALC读写权限，缺省公共读
        private: 私有   ['private', 'public-read', 'default']
        public-read： 公共读
        default: 继承bucket权限
        */
        acl: {
            type: String,
            default: 'private',
        },
        // 上传的列表,用于详情显示
        fileList: {
            type: Array,
            default: () => []
        },
        fileListName: {
            type: String,
            default: 'name'
        },
        fileListUrl: {
            type: String,
            default: 'path'
        },
        disType: {
            type: String,
            default: 'primary'
        },
        // 是否自动上传
        autoUpload: {
            type: Boolean,
            default: true
        },
        // 限制文件大小 单位 mb
        maxSize: {
            type: Number,
            default: () => {
                return 200
            }
        },
        // 允许上传的文件格式  参考 https://www.bluestep.cc/html5-file%E8%A1%A8%E5%8D%95accept%E7%9A%84%E7%A7%8D%E7%B1%BB%E6%B1%87%E6%80%BB/
        fileType: {
            type: Array,
            default: () => {
                return ['images/png', 'images/jpeg', 'images/bmp']
            }
        },
        // 允许上传的文件数量
        limit: {
            type: Number,
            default: () => {
                return 1
            }
        },
        // 上传按钮显示文字
        buttonText: {
            type: String,
            default: '上传'
        },
        // 上传文字大小
        fontSize: {
            type: String,
            default: '12'
        },
        // 提示
        tips: {
            type: String,
            default: ''
        },
        // 文件大小（返回用）
        size: {
            type: Number,
            default: () => {
                return 0
            }
        },
        // 文件名称（返回用）
        name: {
            type: String,
            default: ''
        },
        // 文件类型（返回用）
        type: {
            type: String,
            default: ''
        },
        // 是否展示文件名
        isShowName: {
            type: Boolean,
            default: false
        },
        // 是否显示上传文件
        isShowFile: {
            type: Boolean,
            default: false
        },
        // 提示支持格式文字
        placeholder: {
            type: String,
            default: ''
        },
        formatTip: {
            type: Array,
            default: () => [] //'png', 'jeg', 'bmp'
        },
        isUpload: {
            type: Boolean,
            default: false
        },
        // 上传提示语
        sucTips: {
            type: String,
            default: () => '上传成功！'
        },
        // 是否要提示语
        isSucTips: {
            type: Boolean,
            default: () => true
        },
        // loading是否外部控制, 使用:isLoading.sync="{}"修饰符
        isLoading: {
            type: Object,
            default: () => {
                return {
                    loading: false,
                    type: 0 // 0 不需要，1 是需要
                }
            }
        },
        // 父组件当条数据的下标
        idx: {
            type: Number,
            default: () => {
                return 0
            }
        },
        // 是否可以选择多张
        multiple: {
            type: Boolean,
            default: () => false
        },
        // 文件名称
        fileNames: {
            type: String,
            default: () => ''
        },
    },
    data() {
        return {
            loading: false,
            disabled: false,
            uploadFileList: []
        }
    },
    watch: {
        fileList() {
            this.conversion();
        }
    },
    created() {
        this.conversion();
    },
    methods: {
        //显示上传处理
        conversion() {
            if (!this.fileList || !this.fileList.length) return (this.uploadFileList = [])
            this.uploadFileList = this.fileList.map(item => {
                return {
                    fileName: item[this.fileListName],
                    fileUrl: item[this.fileListUrl]
                }
            })
        },
        // 预览文件
        onPreview(item, index) {
            window.open(this.$utils.toOss(item.fileUrl))
        },

        // 删除文件
        async onDelete(item, index) {
            this.uploadFileList.splice(index, 1)
            this.$emit('fileList', this.uploadFileList, this.idx)
        },

        // 删除文件
        remove() {
            this.$emit('input', '')
            this.$emit('fileList', [])
            this.$refs.upload.clearFiles()
        },

        beforeAvatarUpload(file) {
            if (file.size === 0) {
                this.$refs.upload.clearFiles()
                return this.$message.warning('请不要上传空文件！')
            }
            let isType = true
            let suffix = file.name.split('.')
            if (this.formatTip && this.formatTip.length && this.formatTip.indexOf(suffix[suffix.length - 1]) < 0) {
                isType = false
            }

            const isLtSize = file.size / 1024 / 1024 < this.maxSize
            if (!isType) {
                this.$message.warning('上传格式须为' + this.formatTip.join('、') + '的格式')
                return false
            }
            if (!isLtSize) {
                this.$message.warning(`上传文件大小不能超过 ${this.maxSize}mb!`)
                return false
            }
            // return isType && isLtSize && fileLimit
        },

        // 执行上传
        upLoad(res) {
            if (res.file.size === 0) {
                this.$refs.upload.clearFiles()
                return this.$message.warning('请不要上传空文件！')
            }
            if (this.isLoading.type === 1) {
                this.$emit('update:isLoading', {
                    loading: true,
                    type: 1
                })
            } else {
                this.loading = true
            }
            // this.loading = true
            let pos = res.file.name.lastIndexOf('.') // 扩展名
            let fileExten = res.file.name.substring(pos, res.file.name.length) // 文件名称 不含扩展名
            console.log(this.fileNames)
            let fileNames = this.fileNames ? this.fileNames + '/' : ''
            let storeAs = `crm/${process.env.VUE_APP_ENV}/files/${fileNames}${new Date().getTime()}${fileExten}` //文件存储路径 路径文件可自定义
            this.multipartUploadApi(storeAs, res.file)
        },

        multipartUploadApi(storeAs, file) {
            this.$utils.multipartUpload(file,this.acl === 'public-read').then(url => {
                let uploadFileArr = JSON.parse(JSON.stringify(this.uploadFileList))
                if (this.isLoading.type === 0) {
                    this.loading = false
                }
                if (this.limit === 1) {
                    this.uploadFileList = [{ fileName: file.name, fileUrl: url, fileSize: file.size }]
                } else {
                    // 是否支持多选
                    if (this.multiple) {
                        if (uploadFileArr.length > 0) {
                            let repet = false; // 是否有相同的
                            for (let i = 0; i < uploadFileArr.length; i++) {
                                if (this.uploadFileList[i].fileName === file.name) {
                                    this.uploadFileList[i] = { fileName: file.name, fileUrl: url, fileSize: file.size }
                                    repet = true;
                                }
                            }
                            if (!repet) {
                                this.uploadFileList.push({ fileName: file.name, fileUrl: url, fileSize: file.size })
                            }
                        } else {
                            this.uploadFileList.push({ fileName: file.name, fileUrl: url, fileSize: file.size })
                        }

                    } else {
                        if (uploadFileArr.length > 0) {
                            let repet = false; // 是否有相同的
                            for (let i = 0; i < uploadFileArr.length; i++) {
                                if (this.uploadFileList[i].fileName === file.name) {
                                    this.uploadFileList[i] = { fileName: file.name, fileUrl: url, fileSize: file.size }
                                    repet = true;
                                }
                            }
                            if (!repet) {
                                this.uploadFileList.push({ fileName: file.name, fileUrl: url, fileSize: file.size })
                            }
                        } else {
                            this.uploadFileList.push({ fileName: file.name, fileUrl: url, fileSize: file.size })
                        }
                    }
                }
                console.log(this.uploadFileList)
                this.$emit('fileList', this.uploadFileList, this.idx)
                this.$emit('input', url)
                this.$emit('update:size', file.size)
                this.$emit('update:name', file.name)
                this.$emit('update:type', file.type)
                if (this.isSucTips) {
                    this.$message.success(this.sucTips)
                }
                this.$refs.upload.clearFiles()
            }).catch(err => {
                if (this.isLoading.type === 0) {
                    this.loading = false
                } else {
                    this.$emit('update:isLoading', {
                        type: 1,
                        loading: false
                    })
                }
                this.$message.error('上传失败！')
            })
        },
        handleExceed(files) {
            if (this.uploadFileList.length + files.length > this.limit) {
                return this.$message.warning(`当前限制选择 ${this.limit} 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + this.uploadFileList.length} 个文件`);
            }
        },

    }
}
</script>
<style lang="less" scope>
.upload-video {
    display: flex;
    > .tips {
        padding-left: 8px;
        color: #e6a23c;
    }
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409eff;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        text-align: center;
    }
    .avatar {
        display: block;
    }
    .msg {
        color: #ff5e00;
    }
}

.upload-file-list {
    display: inline-block;
    .upload-file-list-li {
        display: inline-block;
        padding-right: 10px;
        margin-top: 5px;
        .upload-file-list-name {
            cursor: pointer;
            color: #409eff;
            padding-right: 5px;
        }
    }
}
</style>
