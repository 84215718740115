import api from './axios';

//登录
export const login = (params) => api.post(`/client-web/user/login`, params)
export const phoneCode = (params) => api.get(`/client-web/user/sendCode`, {params})

// 获取订单的状态
export const getOrderStatus = (params) => api.get(`/client-web/user/orderStatus`, {params})

// 进入项目
export const intoProduct = (params) => api.get(`/client-web/user/intoProduct`, {params})

// 服务端跳转到客户端
export const serLogin = (params) => api.get(`/client-web/user/serLogin`, {params})

//项目列表
export const productList = (params) => api.get(`/client-web/user/productList`, {params})

//文件列表-上传资料页面
export const suppleList = (params) => api.get(`/client-web/supplement/list`, {params})
//上传一个文件
export const uploading = (params) => api.post(`/client-web/supplement/upload`, params)
//输入框搜索
export const search = (params) => api.get(`/client-web/supplement/search`, {params})
//提交
export const fileSubmit = (params) => api.get(`/client-web/supplement/submit`, {params})
//提交勾选栏是否出现
export const taskStatus = (params) => api.get(`client-web/supplement/isUpload`, {params})
// 国家联想
export const querySearchCountry  = (params) => api.get(`/backend/country`, {params})
//展示Table-邮寄原件页面
export const MailInfo = (params) => api.get(`/client-web/listMailInfo`, {params})
//邮寄个人信息
export const mailAddress = (params) => api.get(`/client-web/mailAddress`, {params})
//保存邮寄信息
export const saveMailInfo = (params) => api.post(`/client-web/mail`, params)

//我的评分-基础信息页面
export const assessment = (params) => api.get(`/client-web/information/assessment`, {params})
//删除上传文件
export const deleteF = (params) => api.post(`/client-web/supplement/delete`, params)
// 个人信息表
export const getInfo = (params) => api.get(`/client-web/information/info`,{params})

//项目进度页面
export const progressList = (params) => api.get(`/client-web/user/progress`, {params})
//获取订单产品详情
export const getProduct = (params) => api.get(`/business/expert/product`, {params})

// -----  star 优才   ------
//保存基础信息
export const saveInformation = (params) => api.post(`/client-web/information/saveInformation`, params)
//保存家庭成员信息
export const saveSubset = (params) => api.post(`/client-web/information/saveSubset`, params)
//个人信息表
export const userInfo = (params) => api.get(`/client-web/information/info`, {params})
//保存背景信息
export const saveQuestion = (params) => api.post(`/client-web/information/saveQuestion`, params)
//保存工作经历
export const saveWork = (params) => api.post(`/client-web/information/saveWork`, params)
//保存居住信息
export const saveResidence = (params) => api.post(`/client-web/information/saveResidence`, params)
//保存学历专业
export const saveQualiAndEduca = (params) => api.post(`/client-web/information/saveQualificationAndEducation`, params)
//提交
export const userSubmit = (params) => api.get(`/client-web/information/submit`, {params})
//模糊查询学校信息
export const schoolList = (params) => api.get(`/client-web/information/schoolList`, {params})
// -----  end 优才   ------

// -----  star 专才   ------
// 专才详情回显
export const expertOrder = (params) => api.get(`/business/expert/order`, {params})
// 第一步(保存申请人信息)
export const expertFirst = (params) => api.post(`/business/expert/first`, params)
// 第二步(保存家庭成员信息)
export const expertSecond = (params) => api.post(`/business/expert/second`, params)
// 第三步(保存基本资格)
export const expertThird = (params) => api.post(`/business/expert/third`, params)
// 第四步(保存学历/专业资格)
export const expertFourth = (params) => api.post(`/business/expert/fourth`, params)
// 第五步(保存就业/雇主信息)
export const expertFifth = (params) => api.post(`/business/expert/fifth`, params)
// 第六步(受聘人员职位详情)
export const expertSixth = (params) => api.post(`/business/expert/sixth`, params)
// 删除家庭成员接口
export const expertDelSub = (params) => api.delete(`/business/expert/subset/`+params)
// 删除资格证书接口
export const expertDelEdu = (params) => api.delete(`/business/expert/education/`+params)
// 删除证书接口
export const expertDelQua = (params) => api.delete(`/business/expert/qualification/`+params)
// 删除工作经历接口
export const expertDelWork = (params) => api.delete(`/business/expert/work/`+params)
// -----  end 专才   ------

// -----  star 留学   ------
//保存主观性评估
export const saveSubjectiveEvaluation = (params) => api.post(`/client-web/information/saveSubjectiveEvaluation`, params)
// 个人信息表
export const studyInformation = (params) => api.get(`/client-web/information/info`, {params})
// 获取方案定位
export const orderPlan = (params) => api.get(`/client-web/order/plan`, {params})
// 选择方案定位
export const chooseOrderPlan = (params) => api.put(`/client-web/order/plan/operate`+params)
// -----  end 留学   ------

// 项目进度-服务端联动
export const getGangedProgress = (params) => api.get(`/client-web/user/gangedProgress`, {params})
// 客户端判断提交
export const handleAlter = (params) => api.get(`/business/alter`, {params})

// 一键登录客户端
export const loginToClient= data => api.post(`/business/jwt/generateTokenFromClient`, data);

// 客户端信息获取 - 用于获取优才项目是否已完成
export const getClientInfo  = (params) => api.get(`/business/client/information/progress-bar`, {params})

export const getCompanys = (params)=> api.get(`/business/informationSearch/enterprise-list`, {params})

// 保存组织架构图数据
export const saveOrgGraphData = params => api.post(`/client-web/document/updateSupplementChartDataByDocumentId`, params)

// 系统审核
export const systemReAudit = params => api.post(`/business/cert-upload/cert-system-recognition-and-audit`, params)

// 获取oss接口
export const getTempUrl = (params) => api.get(`/business/common-logic/access-url`, {params})

// 多文件上报接口
export const multipleReport = params => api.post(`/business/common-logic/batch-upload-report`, params)

// 拼音获取
export const getPingYin = (params) => api.get(`client-web/information/getPingYin`, {params})
// 拼音邮编
export const getZipCode = (params) => api.get(`client-web/information/getZipCode`, {params})

// 引流二维码弹窗提醒数据获取
export const getNotifyStatus = (params) => api.get(`/business/client/user/mini-gzh-notify/status`, {params})

// 引流二维码弹窗提醒-暂不提醒
export const postNotifySubmit = params => api.post(`/business/client/user/mini-gzh-notify/submit`, params)

/*
* @description OSS上传
* @params1 file file对象
* @params2 isPrivate 是否私有化
* @date 2021-12-27
*
*/
export const uploadOss = (file,isPrivate)=>{
    const businessCode = isPrivate  ? 4002002:5000000
    const formData = new FormData()
    formData.append('file',file)
    // return fetch(`${process.env.VUE_APP_OSS_URL}/oss/upload/storage`,{
    //     method:'POST',
    //     mode:'cors',
    //     headers:{
    //         'Content-type':'multipart/form-data',
    //         'businessCode':businessCode,
    //         'token':localStorage.getItem('token')
    //     },
    //     body:new URLSearchParams(formData)
    // })
    return api.post(`${process.env.VUE_APP_OSS_URL}/oss/upload/storage`,formData,{
        headers:{
            'Content-type':'multipart/form-data',
            'businessCode':businessCode,
            'token':localStorage.getItem('token')
        }
    })
}

// 主申请人保存
export const smallInformationSave = data => api.post(`/client-web/small-information/information-save`, data)

// 主申请人提交
export const smallInformationSubmit = data => api.post(`/client-web/small-information/information-submit`, data)

// 获取详情接口
export const getInformationDetail = (params) => api.get(`/client-web/small-information/information-detail`, {params})

// 受养人保存
export const smallSubsetSave = data => api.post(`/client-web/small-information/subset-save`, data)

// 受养人提交
export const smallSubsetSubmit = data => api.post(`/client-web/small-information/subset-submit`, data)
