/**
 * @description 全局显示级别权限指令(控制页面元素显示权限)
 * @author Jeff.Guo
 * @example <el-button type="text" v-auth="1">删除</el-button>
 */
const vAuth = {

    inserted: (el, binding) => {
        let auths = [1,2,3,4,5];
        // 兼容一些特殊的设置需求，可以传对象多个参数
        if(Object.prototype.toString.call(binding.value) === '[object Object]'){
            if(auths.indexOf(binding.value.key) === -1) {
                if(binding.value.type === 1){
                    el.remove()
                } else {
                    el.style.cursor = 'not-allowed';
                    el.style.pointerEvents = 'none';
                }
            }
        } else {
            if(auths.indexOf(binding.value) === -1) {
                el.remove()
            }
        }
    }

};
export default vAuth