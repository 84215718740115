/**
 * @description 公共过滤器
 * @author Jeff Guo
 */
export default {
    // 超过显示字符数以省略号显示
    cutStr: (v) => {
        if (!v) return;
        if (v.length > 50) return v.substr(0, 50) + "…";
        return v;
    },
    // 时间转换为年月日
    getDate: (v) => {
        if (!v) return;
        let strArr = v.split('-');
        let year = strArr[0];
        let month = strArr[1];
        let day = strArr[2];
        let date = "";
        if (strArr.length === 3) {
            date = (year + '年' + month + '月' + day + '日').toString();
        } else if (strArr.length === 2) {
            date = (year + '年' + month + '月').toString();
        } else if (strArr[0] === '至今') {
            date = '至今'
        }
        return date;
    },
    //婚姻状态 1=已婚,2=未婚,3=离婚,4=分居 5-丧偶
    marryInfo: (v) => {
        let a = parseInt(v);
        switch (a) {
            case 1:
                return "已婚";
            case 2:
                return "未婚";
            case 3:
                return "离婚";
            case 4:
                return "分居";
            case 5:
                return "丧偶";
            default:
                break;
        }
    },
    //性别 0为未知,1为男,2为女
    sexInfo: (v) => {
        let a = parseInt(v);
        switch (a) {
            case 0:
                return "未知";
            case 1:
                return "男";
            case 2:
                return "女";
            default:
                break;
        }
    },
    //关系:1：主申父亲,2：主申母亲,3:配偶 ,4：子女,5：兄弟姐妹
    relationFilter: (v) => {
        let a = parseInt(v);
        switch (a) {
            case 1:
                return "父亲";
            case 2:
                return "母亲";
            case 3:
                return "配偶";
            case 4:
                return "子女";
            case 5:
                return "兄弟姐妹";
            default:
                break;
        }

    },
    // 语言能力
    languageFilter: (v) => {
        switch (v) {
            case 1:
                return "国外大学（英语国家）毕业，英文授课，获得学位证（如为中外联合办学，在中国上课的，不算）";
            case 2:
                return "有近两年的雅思或托福成绩单（雅思考试G类总分达6分；托福笔试达550分，计算机达213分，网考达80分）";
                // case 3:
                //     return "不满足以上两个条件，但英文基础较好，有能力在近期参加雅思或托福考试并获取合格成绩单。成绩单可在递交申请后的三个月之内补交（您可报读短期辅导培训班补习英文，然后马上参加考试）。";
            case 4:
                return "非英文的外语，如：法文、日文等（需提供相关证书）";
            case 5:
                return "仅中文";
            default:
                break;
        }

    },
    //职位水平类别
    professionalLevelFilter: (v) => {
        switch (v) {
            case 1:
                return "高层管理岗";
            case 2:
                return "中层管理岗";
            case 3:
                return "低层管理岗";
            case 4:
                return "专业人士";
            case 5:
                return "半专业人士";
            case 6:
                return "其他";
            default:
                break;
        }
    },
    // 是否有学位证
    hasDegreeFilter: (v) => {
        switch (v) {
            case 0:
                return "无学位";
            case 1:
                return "学士";
            case 2:
                return "硕士";
            case 3:
                return "博士";
            case 4:
                return "无学位（硕士）";
            case 5:
                return "无学位（本科）";
            case 6:
                return "无学位（专科）";
            default:
                break;
        }
    },
    // 工作经验
    workExperienceFilter: (v) => {
        switch (v) {
            case 1:
                return "获得最低学位证以后，拥有不超过2年工作经验";
            case 2:
                return "获得最低学位证以后，拥有5年以上的工作经验";
            case 3:
                return "获得最低学位证以后，拥有5年以上的工作经验，其中2年以上担任高级职位";
            case 4:
                return "获得最低学位证以后，拥有10年以上的工作经验，其中5年以上担任高管领导职位";
            case 5:
                return "获得最低学位证以后，拥有不超过2年工作经验";
            default:
                break;
        }
    },
    // 审核结果
    findingsFilter: (v) => {
        switch (v) {
            case 0:
                return "待审核";
            case 1:
                return "通过";
            case 2:
                return "驳回";
            case 3:
                return "--";
        }
    },
    // 语言能力
    levelFilter: (v) => {
        switch (v) {
            case 1:
                return "中文及英文";
            case 2:
                return "中文及英文";
            case 3:
                return "中文及英文";
            case 4:
                return "中文及另一种外语";
            case 5:
                return "";
            default:
                break;
        }
    },
    // 辅助资料
    supplyFilter: (v) => {
        switch (v) {
            case 3:
                return "不确定";
            case 1:
                return "是";
            case 2:
                return "否";
            default:
                break;
        }
    },
}
