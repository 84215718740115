<!--
 * @Author: Vinota
 * @Description: 登陆页面
-->
<template>
    <div class="">
        <template v-if="!$route.query.sign">
            <div class="login">
                <div class="top-icon">
                    <div class="top-cont">
                        <img class="logo-cmage" src="~@/assets/img/login/logo.png" />
                        <h1 class="logo-title">海外身份智能申请系统</h1>
                    </div>
                </div>
                <div class="login-top">
                    <div class="wx-left">
                        <div class="wx-left-t">
                            <div class="wx-scan">微信扫码登录小程序填写上传更便捷</div>
                            <img src="~@/assets/img/sun_qr.png" class="left-chart" alt="" />
                            <div class="wx-mc">智汇银河小程序</div>
                        </div>
                        <div class="wx-left-f">
                            <div class="wx-scan">微信扫码关注公众号消息通知更及时</div>
                            <img src="~@/assets/img/public_qrcode.png" class="left-chart" alt="" />
                            <div class="wx-mc">智汇银河Service公众号</div>
                        </div>
                    </div>
                    <!-- <img src="https://upload-cdn.galaxy-immi.com/public/img/login.png" class="left-chart" alt="" /> -->
                    <div class="center-logininfo">
                        <div class="input-div">
                            <el-form ref="ruleForm" size="max" :model="model" :rules="rules" label-width="80px" label-position="top">
                                <p class="account-login">账户登录</p>
                                <p class="login-tip">请输入签约手机号码或签约邮箱登录</p>
                                <el-form-item prop="phoneNum">
                                    <el-input v-model="model.phoneNum" clearable class="login-username" placeholder="请使用签约手机号码或签约邮箱登录" @keyup.enter="handleLogin" />
                                </el-form-item>
                                <el-form-item prop="code">
                                    <div class="ercode-iput">
                                        <el-input v-model="model.code" class="login-password" maxlength="6" clearable placeholder="请输入验证码" @keyup.enter.native="handleLogin" />
                                    </div>
                                    <el-button class="ercode-btn" :disabled="show" @click="getphonecode()">{{ codeTip }}</el-button>
                                </el-form-item>
                                <el-button type="primary" size="max" style="width: 100%" class="login-btn" @click="submitForm('ruleForm')">登录</el-button>
                            </el-form>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <!-- CRM文书制作-写作列表生成链接登录 -->
        <doc-login v-else />
    </div>
</template>

<script>
import DocLogin from '../components/DocLogin/DocLogin.vue'
import { phoneCode } from "@/api/workfow.js";
export default {
    name: "Login",
    components: {
        DocLogin  // CRM文书制作-写作列表生成链接登录
    },
    data() {
        var validateEmailOrPhone = (rule, value, callback) => {
            var regPhone = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
            var regEmail =
                /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/;
            if (regPhone.test(value) || regEmail.test(value)) {
                return callback();
            } else {
                callback(new Error("请输入正确的手机号或邮箱"));
            }
        };
        return {
            envs: process.env.VUE_APP_ENV === 'production',
            isShowErrorMsg: false,
            loginErrorMsg: "",
            model: {
                phoneNum: "",
                code: "",
            },
            rules: {
                phoneNum: [
                    {
                        required: true,
                        message: "请填写手机号或邮箱",
                        trigger: "blur",
                    },
                    {
                        validator: validateEmailOrPhone,
                        message: "请输入正确的手机号或邮箱",
                        trigger: "blur",
                    },
                ],
                code: [
                    {
                        required: true,
                        message: "请输入验证码",
                        trigger: "blur",
                    },
                ],
            },
            codeTip: "获取验证码", //验证码按钮上显示的文本,
            show: false, //发送验证码按钮是否可以被点
            loading: false,
            codeNum: 0,
            code10MinutesTime: ''
        };
    },
    created() {
        // 智能写作链接跳转判断是否有缓存,有的话直接到文书稿件,否则输入密码 通过orderId做判断,因为有可能单不一样
        const user = JSON.parse(localStorage.getItem('user'))
        if (user && user.orderId == this.$route.query.orderId) {
            localStorage.setItem('isList', '')
            localStorage.setItem('stepEnd', '')
            if (this.$route.query.userId && this.$route.query.orderId && this.$route.query.sign) {
                // 文书稿件登录判断移动端还是pc端跳转到不同界面
                let path = '/Manuscript' //this.$utils.isMobile() ? '/MobileManuscript' : '/Manuscript'
                this.$router.push({
                    path: path,
                    query: this.$route.query
                })
            }
        }
    },
    beforeDestroy() {
        clearInterval(this.timer);
    },
    methods: {
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.handleLogin();
                } else {
                    return false;
                }
            });
        },
        //点击登录按钮
        async handleLogin() {
            this.$store.dispatch("login", this.model);
        },
        //发送手机验证码
        async getphonecode() {
            if (
                !/^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(this.model.phoneNum) &&
                !/^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/.test(
                    this.model.phoneNum
                )
            ) {
                this.$message.error("请输入正确的手机号或邮箱");
            } else {
                if (this.model.phoneNum.indexOf('@') === -1) {
                    // 如果超过10分，重置点击次数
                    if (this.code10MinutesTime && this.code10MinutesTime < new Date().valueOf()) {
                        this.codeNum = 0
                    }
                    // 记录用户多次点击获取验证码
                    this.codeNum++
                    if (this.codeNum === 1) {
                        // 10分后时间
                        this.code10MinutesTime = new Date(Date.parse(new Date()) + (10*60*1000)).valueOf()
                    }
                    // 客户10分钟内第三次点击的，提醒用户
                    if (this.codeNum >= 3 && this.code10MinutesTime > new Date().valueOf()) {
                        this.$confirm('亲! 是不是没有收到验证码? 不着急，您还可以使用签约时登记的邮箱登录系统哦，如有疑问可咨询您的服务管家！', '提示', {
                            confirmButtonText: '继续获取验证码',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(() => {
                            this.getCode()
                        })
                        return false
                    }
                }
                this.getCode()
            }
        },
        // 获取验证码
        async getCode() {
            //设置验证码的倒计时时间
            let time = 60;
            //验证码不为空-发送请求
            let res = await phoneCode({
                phoneNum: this.model.phoneNum,
            });
            if (res.code === 200) {
                //设置请求间隔时间
                this.codeTip = "(60)秒后重发";
                this.timer = setInterval(() => {
                    time--;
                    this.codeTip = `(${time})秒后重发`;
                    if (time <= 0) {
                        clearInterval(this.timer);
                        this.timer = null;
                        this.codeTip = "获取验证码";
                        this.show = false;
                    } else if (time > 0 && time < 60) {
                        this.show = true;
                    }
                }, 1000);
            }
        }
    },
};
</script>

<style lang="less" scoped>
.logo-cmage {
    width: 48px;
    height: 48px;
}

.logo-title {
    font-size: 16px;
    font-weight: 600;
    color: #303133;
    letter-spacing: 1px;
    margin-left: 8px;
}
.wx-scan {
    width: 144px;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 21px;
    text-align: center;
}
.wx-login {
    font-size: 16px;
    margin-bottom: 25px;
}

.login {
    background: #fff;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    position: relative;
    z-index: 100;

    .top-icon {
        width: 100%;
        height: 80px;

        .top-cont {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            box-sizing: border-box;
            padding-left: 8px;
            padding-top: 8px;
        }
    }

    .login-top {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        height: calc(100vh - 80px);
        .center-logininfo {
            width: 432px;
            margin-left: 93px;

            .input-div {
                background: #fff;
                width: 432px;
                padding: 40px 32px 40px;
                box-sizing: border-box;
                margin-top: 20px;
                box-shadow: 0px 4px 8px 0px #e9eef7;
                border-radius: 4px;

                p {
                    color: #303133;
                    line-height: 24px;
                    font-size: 16px;
                    width: 331px;
                }

                .account-login {
                    font-size: 24px;
                    font-weight: bold;
                }

                p.forget {
                    height: 22px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #606266;
                    line-height: 22px;
                    margin-bottom: 20px;
                }
            }

            .center-logininfo-title {
                display: flex;
                align-items: center;
                height: 72px;

                .logo {
                    width: 72px;
                    height: 72px;
                    margin-right: 15px;
                }
            }
        }

        input {
            padding: 0 36px;
            height: 40px;
            width: 368px;
            margin-bottom: 20px;
            background: #ffffff;
            border-radius: 2px;
            border: 1px solid #d9d9d9;
            font-size: 16px;
            font-weight: 400;
            color: #303133;
            line-height: 24px;
            cursor: pointer;

            &::placeholder {
                height: 24px;
                font-size: 16px;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.25);
                line-height: 24px;
            }
        }

        input.login-username {
            background: url("~@/assets/img/login/login-username-icon.png")
                center center no-repeat;
            background-size: 16px 16px;
            background-position-x: 12px;
        }

        input.login-password {
            background: url("~@/assets/img/login/login-password-icon.png")
                center center no-repeat;
            background-size: 16px 16px;
            background-position-x: 12px;
        }
    }

    @media screen and (max-width: 1200px) {
        .login-top {
            padding-bottom: 230px;
            // .wx-left {
            //     display: none;
            // }
            .center-logininfo {
                margin-left: 0;
                margin-top: 0;
                top: 0;
            }
        }
    }
    .wx-left {
        display: flex;
    }
    .wx-left-t {
        margin-bottom: 25px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 48px;
        .left-chart {
            width: 245px;
            height: 245px;
            transition: all 0.3s;
        }
    }
    .wx-left-f {
        display: flex;
        flex-direction: column;
        align-items: center;
        .left-chart {
            width: 245px;
            height: 245px;
            transition: all 0.3s;
        }
    }
    .wx-mc {
        width: 100%;
        text-align: center;
        color: #979797;
        font-size: 14px;
        font-weight: 400;
        margin-top: 16px;
    }

}

.login-tip {
    font-size: 16px;
    font-weight: 400;
    color: #606266;
    margin: 16px 0 8px;
}

.ercode-iput {
    width: 258px;
    display: inline-block;
}

.ercode-btn {
    display: inline-block;
    width: 102px;
    height: 40px;
    border-radius: 2px;
    border: 1px solid #dcdfe6;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: #909399;
    position: relative;
    top: 1px;
    cursor: pointer;
    margin: 0;
    padding: 0;
    outline: none !important;
    margin-left: 8px;
}

/deep/.login-btn span {
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
    letter-spacing: 6px;
}
</style>
