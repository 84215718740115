<!--
 * @Author: filename
 * @Description: 省市选择组件 2021-03-12
 * @example <CitySelect v-model="city"/>
 * @props ['广东', '深圳']
-->
<template>
    <el-cascader
        :key="cascaderKey"
        filterable
        clearable
        style="width: 190px"
        :value="value"
        :options="filterCity"
        :placeholder="placeholder"
        :popper-append-to-body="false"
        @change="handleChange"
    />
</template>

<script>
import cityData from './citys'
export default {
    name: 'CitySelect',
    props: {
        value: {
            type: Array,
            default: () => {
                return []; //['广东', '深圳']
            }
        },
        placeholder: { //输入框占位文本
            type: String,
            default: "请选择城市"
        },
        country: {
            type: String,
            default: ""
        },
    },
    data () {
        return {
            cityData,
            cascaderKey: 1,
        }
    },
    computed: {
        filterCity() {
            switch (this.country) {
                case "中国香港-Hong Kong":
                    return this.cityData.filter(item => item.label.includes('香港'))
                case "中国澳门-Macau":
                    return this.cityData.filter(item => item.label.includes('澳门'))
                case "中国台湾-Taiwan":
                    return this.cityData.filter(item => item.label.includes('台湾'))
                default:
                    return this.cityData
            }
        }
    },
    watch: {
        // 切换数据源时el-cascader默认会报错，设定唯一key
        filterCity() {
            this.cascaderKey++
            this.$emit("input", [])
        }
    },
    methods: {
        handleChange(v){
            this.$emit("input", v);
        }
    }
}
</script>
<style scoped lang="less">
    /deep/.el-scrollbar .el-cascader-menu__wrap{
        margin-bottom: -16px!important;
    }

</style>
