/**
 * @description 批量注册全局指令
 * @author Jeff.Guo
 */

import copy from './copy/copy';
import auth from './auth/auth';
import isImg from './isImg/isImg';
import showp from './showPlaceholder/showPlaceholder';
import number from './number/number';
import drag from "./drag/drag";
const directives = {
    copy,auth,isImg,showp, number,drag
};
// 批量注册指令
export default {
    install(Vue) {
        Object.keys(directives).forEach((key) => {
            Vue.directive(key, directives[key]);
        });
    },
};
