import Vue from "vue";
import {
    userInfo,
    login,
    expertOrder,
    getProduct
} from "@/api/workfow.js";
import router from '@/router';
export default {
    // 登录
    login({
        commit
    }, data) {
        localStorage.clear()
        login(data).then(res => {
            if (res.data.token == null) {
                this.$message.error("获取登录认证失败！");
                return;
            }
            if (res.code === 200) {
                // 模拟baseSchedule = 7
                // res.data.baseSchedule = 2;
                // res.data.step = 0;
                let d = {
                    step: res.data.baseSchedule, //后端返回baseSchedule为当前项目step 0,1,2,3,4,5,6
                    userName: res.data.userName, //用户姓名
                    orderId: res.data.orderId, //上次操作的订单id
                    baseSchedule:res.data.baseSchedule,//个人信息填写到第几步
                    hasFinishOnePro: res.data.step, //后端返回step为该项目个人信息填写是否已经做完 0为没做完,1为做完
                    order_sn: res.data.orderSn,
                    userId: res.data.userId,
                    phone: res.data.phone
                }
                localStorage.setItem('token', res.data.token);
                localStorage.setItem("user", JSON.stringify(d));
                commit("user", res.data);

                // 神策埋点
                Vue.prototype.$sensors.bind("identity_client_user_id", res.data.userId + "")
                Vue.prototype.$sensors.bind("$identity_mobile", res.data.phone + "")

                if (res.data.step === 0 || res.data.step === null) {
                    //个人信息没有填完
                    if (res.data.orderId === '' || res.data.orderId === null) {
                        /**
                         * 服务端跳转过来一般是带有 orderId
                         * 如果没有就去项目列表
                         */
                        router.push({
                            path: "/ProjectList"
                        });
                    } else {
                        // 判断当前是优才还是专才
                        getProduct({
                            order_id: res.data.orderId
                        }).then(ress => {
                            console.log(ress)
                            if (ress.code === 200) {
                                localStorage.setItem("YhorderType", ress.data.product_id.type);
                                localStorage.setItem(
                                    'product',
                                    JSON.stringify(ress.data.product_id)
                                )
                                commit("setType");
                                console.log(ress.data.product_id.type)
                                if (ress.data.product_id.type === '优才') {
                                    // 是优才且没填完
                                    let env = process.env.VUE_APP_USERINFO_URL
                                    window.location.href = `${env}/MiddleLogin/${data.phoneNum}/${data.code}`
                                } else if (ress.data.product_id.type === "专才") {
                                    router.push({
                                        name: "etep" + (Number(res.data.baseSchedule + 1)),
                                        params: {
                                            id: res.data.orderId
                                        }
                                    });
                                } else if (
                                    ress.data.product_id.type === "留学"
                                ) {
                                    router.push({
                                        name: "ltep" + (Number(res.data.baseSchedule) + 1),
                                        params: {
                                            id: res.data.orderId,
                                        },
                                    });
                                } else {
                                    if (ress.data.product_id.name === "新增随迁人员") {
                                        console.log('新增随迁人员', res.data.orderId);
                                        // 新增随迁人员
                                        window.location.href = `${process.env.VUE_APP_USERINFO_URL}/UserInfo/Transfer/${res.data.orderId}`
                                    } else {
                                        // 小项目优才
                                        const smallProductIds = [29, 55, 30, 32, 96] // 这五类产品走新逻辑，其他继续走以前逻辑
                                        if (smallProductIds.includes(ress.data.product_id.id)) {
                                            router.push({
                                                name: `SmallStep${(Number(res.data.baseSchedule) + 1)}`,
                                                params: {
                                                    id: res.data.orderId,
                                                },
                                                query: {
                                                    id: ress.data.product_id.id // 产品ID
                                                }
                                            });
                                        } else {
                                            router.push({
                                                name:
                                                    "ptep" +
                                                    (Number(res.data.baseSchedule) + 1),
                                                params: {
                                                    id: res.data.orderId,
                                                },
                                            });
                                        }
                                    }
                                }
                            }
                        });
                    }
                } else if (res.data.step != 0) {
                    //个人信息7步已经填完
                    localStorage.setItem('isList', 0);
                    commit('user');
                    // 判断当前是优才还是专才
                    getProduct({
                        order_id: res.data.orderId,
                    }).then((ress) => {
                        if (ress.code === 200) {
                            localStorage.setItem(
                                'product',
                                JSON.stringify(ress.data.product_id)
                            )

                            if (ress.data.product_id.type == '随迁') {
                                res.data.baseSchedule === 4 ? localStorage.setItem("stepEnd", 1) : localStorage.setItem("stepEnd", 0);
                            } else {
                                res.data.baseSchedule === 7 ? localStorage.setItem('stepEnd', 1) : localStorage.setItem('stepEnd', 0);
                            }
                            localStorage.setItem(
                                "YhorderType",
                                ress.data.product_id.type
                            );
                            localStorage.setItem(
                                "YhorderName",
                                ress.data.product_id.name
                            );
                            commit("setType");
                            commit("setName");
                        }
                        router.push({
                            path: "/HandleProgress"
                        });
                    });

                }
            } else if (res.code === 403) {
                localStorage.clear()
                router.push({
                    path: "/Login"
                });
            }
        });

    },
    // 获取用户个人信息
    getUserInfo({
        commit
    }, orderId) {
        userInfo({
            orderId
        }).then(res => {
            if (res.code === 200) {
                commit('setUserinfo', res.data);
            }
        });
    },
    // 获取7步骤回显信息(优才)
    getInfoData({
        commit
    }, orderId) {
        return new Promise((resolve, reject) => {
            userInfo({
                orderId
            }).then(res => {
                if (res.code === 200) {
                    resolve(res)
                } else {
                    reject()
                }
            });
        });
    },
    // 获取7步骤回显信息(专才)
    expertData({
        commit
    }, order_id) {
        return new Promise((resolve, reject) => {
            expertOrder({
                order_id
            }).then(res => {
                if (res.code === 200) {
                    resolve(res)
                } else {
                    reject()
                }
            });
        });
    },
};
