 export default {
    token: "",
    user: {
        userName:''
    },
    isList: '',
    stepEnd: '',
    name: '',
    type: ''
}

