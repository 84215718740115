<!--
* @name：视频直传OSS组件
* @author：Jeff Guo
-->
<template>
    <div>
        <el-upload
            ref="upload"
            class="upload-video"
            action="/"
            name="name"
            :accept="fileType.join(',')"
            :on-remove="remove"
            :limit="1"
            :before-upload="beforeAvatarUpload"
            :http-request="upLoad"
            :auto-upload="autoUpload"
        >
            <el-button slot="trigger" size="small" type="primary" :loading="loading">{{ buttonText }}</el-button>
            <el-button v-if="!autoUpload" style="margin-left: 10px;" size="small" type="success" :loading="loading" @click="$refs.upload.submit()">
                上传到服务器
            </el-button>
            <span slot="tip" class="tips">{{ tips }}</span>
        </el-upload>
        <div v-if="isShowName">{{ fileName }}</div>
    </div>
</template>

<script>
import { isHeic } from '../../utils/utils'
import heic2any from 'heic2any';
export default {
    name: "UploadFiles",
    props: {
        value: {
            type: String,
            default: ""
        },
        /*
        设置OSS文件ALC读写权限，缺省公共读
        private: 私有   ['private', 'public-read', 'default']
        public-read： 公共读
        default: 继承bucket权限
        */
        acl: {
            type: String,
            default: 'public-read',
        },
        // 是否自动上传
        autoUpload: {
            type: Boolean,
            default: true
        },
        // 限制文件大小 单位 mb
        maxSize: {
            type: Number,
            default: () => {
                return 100000
            }
        },
        // 允许上传的文件格式  参考 https://www.bluestep.cc/html5-file%E8%A1%A8%E5%8D%95accept%E7%9A%84%E7%A7%8D%E7%B1%BB%E6%B1%87%E6%80%BB/
        fileType: {
            type: Array,
            default: () => {
                return ['image/png','image/jpeg','application/vnd.ms-powerpoint']
            }
        },
        // 允许上传的文件数量
        limit: {
            type: Number,
            default: () => {
                return 1
            }
        },
        // 上传按钮显示文字
        buttonText: {
            type: String,
            default: "选择文件"
        },
        // 提示
        tips: {
            type: String,
            default: ""
        },
        // 文件大小（返回用）
        size: {
            type: Number,
            default: () => {
                return 0
            }
        },
        // 文件名称（返回用）
        name: {
            type: String,
            default: ""
        },
        // 文件类型（返回用）
        type: {
            type: String,
            default: ""
        },
        // 是否展示文件名
        isShowName: {
            type: Boolean,
            default: false
        },
        id:{ //上传按钮对应的id值
            type: Number,
            default: () => {
                return 0
            }
        },
        // 文件名称
        fileNames: {
            type: String,
            default: ''
        },
    },
    data () {
        return {
            loading: false,
            fileName: ""
        };
    },
    methods: {
        // 删除文件
        remove() {
            this.$emit("input", "");
            this.$refs.upload.clearFiles()
        },
        async ossUpload(file) {
            // let pos = file.name.lastIndexOf(".");
            // let fileExten = file.name.substring(pos, file.name.length);
            // let timestamp = new Date().getTime();

            // let fileNames = this.fileNames ? this.fileNames + '/' : ''
            // let storeAs = `crm/${process.env.VUE_APP_ENV}/files/${fileNames}${timestamp}${fileExten}`;

            this.$utils.multipartUpload(file).then((url)=>{
                this.$message.success('上传成功！')
                this.$emit("input", url)
                this.$emit("update:size", file.size)
                this.$emit("update:name", file.name)
                this.$emit("update:type", file.type)
                this.$emit("success", {
                    id: this.id,
                    fileInfo:{
                        url,
                        name: file.name,
                    }
                })
                this.fileName = file.name
                this.$utils.fileReport(url)
                this.$refs.upload.clearFiles()
            }).catch(()=>{
                this.$message.error('上传失败！')
                this.$emit("success", false);
            })
        },
        // 执行上传
        upLoad(res) {
            this.loading = true
            const _this = this
            const fileReaderBuffer = new FileReader();
            // 读取是否是heic格式图片
            fileReaderBuffer.onload = () => {
                try {
                    this.ossUpload(res.file)
                } catch(err) {
                    console.log(err)
                } finally {
                    this.loading = false
                }
            }
            fileReaderBuffer.readAsArrayBuffer(res.file);
        },

        beforeAvatarUpload(file) {
            let isType = true
            if (this.fileType.length > 0) {
                isType = (this.fileType.indexOf(file.type) > -1);
            }
            const isLtSize = file.size / 1024 / 1024 < this.maxSize;
            if (!isType) {
                this.$message.error('不支持的上传格式');
            }
            if (!isLtSize) {
                this.$message.error(`上传文件大小不能超过 ${this.maxSize}mb!`);
            }
            return isType && isLtSize;
        }
    }
}

</script>
<style lang="less">
    .upload-video {
        display: flex;
        > .tips {
            padding-left: 8px;
            color: #E6A23C;
        }
        .avatar-uploader .el-upload {
            border: 1px dashed #d9d9d9;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
        }
        .avatar-uploader .el-upload:hover {
            border-color: #409EFF;
        }
        .avatar-uploader-icon {
            font-size: 28px;
            color: #8c939d;
            text-align: center;
        }
        .avatar {
            display: block;
        }
        .msg{
            color: #ff5e00
        }
    }
</style>
