<template>
    <div>
        <!-- pc -->
        <div v-if="isShow()" class="login">
            <div class="top-icon">
                <div class="top-cont">
                    <img class="logo-cmage" src="~@/assets/img/login/logo.png" />
                    <h1 class="logo-title">海外身份智能申请系统</h1>
                </div>
            </div>
            <div class="login-top">
                <img src="https://upload-cdn.galaxy-immi.com/public/img/login.png" class="left-chart" alt="" />
                <div class="center-logininfo doc-login">
                    <div class="doc-form">
                        <div class="doc-tip">请输入文案提供的密码:</div>
                        <el-form ref="ruleForm" size="max" :model="ruleForm" :rules="rules">
                            <el-form-item prop="pwd">
                                <el-input v-model="ruleForm.pwd" maxlength="6" show-password clearable placeholder="请输入五位数密码" />
                            </el-form-item>
                            <el-button type="primary" size="max" style="width: 100%" :loading="isLogin" @click="submitForm('ruleForm')">登录</el-button>
                        </el-form>
                        <div class="time-limit">有效期限：一个月</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 移动端 -->
        <div v-else class="mobile-login">
            <div class="mobile-header">
                <div class="header-box">
                    <img class="mobile-logo" src="~@/assets/img/login/logo.png" />
                    <h1 class="mobile-title">海外身份智能申请系统</h1>
                </div>
            </div>
            <div class="login-pic">
                <img width="100%" src="https://upload-cdn.galaxy-immi.com/public/img/login.png" class="left-chart" alt="" />
            </div>
            <div>
                <div style="font-size: 16px;">请输入文案提供的密码:</div>
                <el-form ref="ruleForm" size="max" :model="ruleForm" :rules="rules" style="margin-top: 15px;">
                    <el-form-item prop="pwd">
                        <el-input v-model="ruleForm.pwd" maxlength="6" show-password clearable placeholder="请输入五位数密码" />
                    </el-form-item>
                    <el-button type="primary" size="max" style="width: 100%;" :loading="isLogin" @click="submitForm('ruleForm')">
                        <span style="font-size: 18px;">登录</span>
                    </el-button>
                </el-form>
            </div>
            <div class="time-limit">有效期限：一个月</div>
        </div>
    </div>
</template>

<script>
import { writingDocumentLogin } from '../../api/manuscript'
export default {
    data() {
        return {
            isLogin: false,
            ruleForm: {
                pwd: '',
            },
            rules: {
                pwd: {
                    required: true,
                    message: "请输入密码",
                    trigger: "blur",
                }
            }
        }
    },
    methods: {
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.writingDocumentLogin()
                } else {
                    return false
                }
            })
        },
        // 登录
        async writingDocumentLogin() {
            try {
                localStorage.clear()
                this.isLogin = true
                const { code, data } = await writingDocumentLogin(
                    {
                        ...this.ruleForm,
                        ...this.$route.query
                    }
                )
                if (code === 200) {
                    localStorage.setItem('token', data.token)
                    localStorage.setItem("user", JSON.stringify({
                        orderId: data.orderId,
                        userName: data.userName,
                        phone: data.phone,
                        userId: data.userId,
                        order_sn: data.orderSn,
                        sex: data.sex
                    }))
                    // 文书稿件登录判断移动端还是pc端跳转到不同界面
                    let path = '/Manuscript'//this.$utils.isMobile() ? '/MobileManuscript' : '/Manuscript'
                    this.$router.push({
                        path: path,
                        query: this.$route.query
                    })
                }
            } finally {
                this.isLogin = false
            }
        },
        isShow() {
            if (!this.$utils.isMobile()) {
                return true
            } else {
                return false
            }
        }
    }
}
</script>
<style lang="less" scoped>
.doc-login {
    background: #fff;
    width: 432px;
    box-sizing: border-box;
    margin-top: 20px;
    box-shadow: 0px 4px 8px 0px #e9eef7;
    border-radius: 4px;
    .doc-form {
        padding: 20px 32px 40px;
    }
    .doc-tip {
        font-size: 14px;
        padding-bottom: 10px;
    }
    .clien-name {
        padding: 20px 32px;
        font-size: 16px;
        border-bottom: 1px solid #eee;
    }
}
.time-limit {
    color: #409EFF;
    text-align: center;
    font-size: 14px;
    margin-top: 15px;
}
// 移动端文书稿件
.mobile-login {
    padding: 20px;
    max-width: 750px;
    height: 100vh;
    background: #fff;
    .mobile-header {
        display: flex;
        justify-content: center;
        .header-box {
            display: flex;
            align-items: center;
        }
        .mobile-logo {
            width: 50px;
        }
        .mobile-title {
            margin-left: 10px;
            font-size: 20px;
            font-weight: bold;
        }
        .login-pic {
            padding: 40px;
        }
    }
}
// 正常登录
.logo-cmage {
    width: 48px;
    height: 48px;
}

.logo-title {
    font-size: 16px;
    font-weight: 600;
    color: #303133;
    letter-spacing: 1px;
    margin-left: 8px;
}

.login {
    background: #fff;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    position: relative;
    z-index: 100;

    .top-icon {
        width: 100%;
        height: 80px;

        .top-cont {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            box-sizing: border-box;
            padding-left: 8px;
            padding-top: 8px;
        }
    }

    .login-top {
        display: flex;
        width: 100%;
        align-items: flex-start;
        justify-content: center;
        overflow: hidden;
        margin-top: 114px;

        .center-logininfo {
            position: relative;
            top: 66px;
            width: 432px;
            height: 100%;
            margin-left: 93px;
            margin-top: 18px;

            .input-div {
                background: #fff;
                width: 432px;
                padding: 40px 32px 40px;
                box-sizing: border-box;
                margin-top: 20px;
                box-shadow: 0px 4px 8px 0px #e9eef7;
                border-radius: 4px;

                p {
                    color: #303133;
                    line-height: 24px;
                    font-size: 16px;
                    width: 331px;
                }

                .account-login {
                    font-size: 24px;
                    font-weight: bold;
                }

                p.forget {
                    height: 22px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #606266;
                    line-height: 22px;
                    margin-bottom: 20px;
                }
            }

            .center-logininfo-title {
                display: flex;
                align-items: center;
                height: 72px;

                .logo {
                    width: 72px;
                    height: 72px;
                    margin-right: 15px;
                }
            }
        }

        input {
            padding: 0 36px;
            height: 40px;
            width: 368px;
            margin-bottom: 20px;
            background: #ffffff;
            border-radius: 2px;
            border: 1px solid #d9d9d9;
            font-size: 16px;
            font-weight: 400;
            color: #303133;
            line-height: 24px;
            cursor: pointer;

            &::placeholder {
                height: 24px;
                font-size: 16px;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.25);
                line-height: 24px;
            }
        }

        input.login-username {
            background: url("~@/assets/img/login/login-username-icon.png")
                center center no-repeat;
            background-size: 16px 16px;
            background-position-x: 12px;
        }

        input.login-password {
            background: url("~@/assets/img/login/login-password-icon.png")
                center center no-repeat;
            background-size: 16px 16px;
            background-position-x: 12px;
        }
    }

    @media screen and (max-width: 1200px) {
        .login-top {
            padding-bottom: 230px;
            >img.left-chart {
                display: none;
            }
            .center-logininfo {
                margin-left: 0;
                margin-top: 0;
                top: 0;
            }
        }
    }

    .left-chart {
        width: 570px;
        height: 570px;
        transition: all 0.3s;
    }
}

.login-tip {
    font-size: 16px;
    font-weight: 400;
    color: #606266;
    margin: 16px 0 8px;
}

.ercode-iput {
    width: 258px;
    display: inline-block;
}

.ercode-btn {
    display: inline-block;
    width: 102px;
    height: 40px;
    border-radius: 2px;
    border: 1px solid #dcdfe6;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: #909399;
    position: relative;
    top: 1px;
    cursor: pointer;
    margin: 0;
    padding: 0;
    outline: none !important;
    margin-left: 8px;
}

/deep/.login-btn span {
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
    letter-spacing: 6px;
}
</style>
