export default {
    // 保存当前用户信息(持久化)
    user(state) {
        state.user = JSON.parse(localStorage.getItem('user'));
        state.token = localStorage.getItem('token');
        state.isList = localStorage.getItem('isList'); //是否显示tab 0是 1否
        state.stepEnd = localStorage.getItem('stepEnd'); //是否完成7步骤 1是 0否
    },
    // 保存当前用户信息
    setUserinfo(state, user) {
        state.userInfo = user;
    },
    // 保存当前订单类型(持久化)
    setType(state) {
        state.type = localStorage.getItem('YhorderType');
    },
    // 保存当前订单产品名称(持久化)
    setName(state) {
        state.name = localStorage.getItem('YhorderName');
    },
    // 保存是点下一步还是step (持久化) 1 step / 0 下一步
    stepChange(state) {
        state.stepChange = localStorage.getItem('stepChange');
    },
};
