import Vue from 'vue';
import axios from "axios";
import router from "@/router/index";
import {
    MessageBox,
    Message
} from "element-ui";
import Toast from '../components/MobileToast/MobileToast'
Vue.use(Toast)
import Dialog from '../components/MobileDialog/MobileDialog'
Vue.use(Dialog)
import { isMobile,signParamsString } from '../utils/utils'

axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
axios.defaults.headers.put['Content-Type'] = 'application/json;charset=utf-8';
axios.defaults.headers.delete['Content-Type'] = 'application/json;charset=utf-8';
axios.defaults.headers.patch['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8';
const api = axios.create({
    baseURL: '',
    timeout: 40000
})
api.interceptors.request.use(
    config => {
        let token = localStorage.getItem("token");
        config.headers["Authorization"] = token;
        config.headers["Token"] = token;
        config.headers['Timestamp'] = Date.now()
        if(config.method.toLowerCase() === 'get'){
            const params = config.params
            if(params && Object.keys(params).length!==0){
                config.headers["Sign"] = signParamsString(params)
            }
        }
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

api.interceptors.response.use(
    response => {
        if (response.status === 200 && response.data.code == 200) {
            return response.data;
        } else if (response.data.code == 403 || response.data.code == 401) {
            localStorage.clear()
            router.push({
                path: "/login"
            });
        } else {
            // 判断是否是移动设备
            if (isMobile()) {
                Vue.prototype.$toastTip.fail(response.data.msg)
            } else {
                Message({
                    showClose: true,
                    type: 'error',
                    message: response.data.msg
                })
            }
            return response.data;
        }
    },
    error => {
        const res = error.response
        let msg = ""
        if (res.status === 400) {
            msg = "400 Bad"
        } else if (res.status === 500) {
            msg = "服务器内部错误"
        } else {
            msg = "未知错误"
        }
        if (isMobile()) {
            Vue.prototype.$confirmTip.showTip(msg)
        } else {
            MessageBox.alert(msg, "报错了", {
                confirmButtonText: "确定",
                type: "error"
            });
        }
        return Promise.reject(error)
    }
)

export default api
