import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from './store/index'

import filters from './filters/filters.js';
import rules from './utils/rules'
import * as utils from './utils/utils'
import directives from './directives';
import components from './components';
import config from './config';
import awesome from "./assets/font-awesome/css/font-awesome.min.css"

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import GalaxyCommon, { DefineEnv } from '@galaxy/user-info'
// mobile 二次确认框
import ConfirmTip from './components/MobileDialog/MobileDialog.js'
// mobile toast提示框
import ToastTip from './components/MobileToast/MobileToast.js'
// sentry接入
import { initSentry } from '@/utils/sentry';

Vue.use(ConfirmTip)
Vue.use(ToastTip)

Vue.config.productionTip = false;
DefineEnv({
    VUE_APP_ENV: process.env.VUE_APP_ENV,
    VUE_APP_BASE_API: process.env.VUE_APP_CLIENT_URL + '/',
    VUE_APP_NODE_URL: process.env.VUE_APP_NODE_URL,
    VUE_APP_DOC_URL: process.env.VUE_APP_DOC_URL,
    VUE_APP_OSS_URL: process.env.VUE_APP_OSS_URL,
})
Vue.use(GalaxyCommon)

// 全局设置UI组件尺寸
Vue.use(ElementUI, {
    size: 'small'
});

// 全局过滤器
Vue.use(filters);

// 全局指令
Vue.use(directives);

// 全局组件
Vue.use(components);

//  图标库
Vue.use(awesome)

// 生存环境神策埋点
utils.sensorsData(Vue)

// 全局混入
Vue.mixin({
    data: function () {
        return {
            count: 0,
            stepChange: false,
        }
    },
    watch: {
        userForm: {
            handler(val) {
                if (val) {
                    this.count++;
                }
            },
            deep: true,
        },
    },
    beforeRouteLeave(to, from, next) {
        // console.log(to)
        // console.log(from)
        // console.log(from.name.indexOf("tep") != -1)
        // console.log('全局混入count=>', this.$data.count)
        // step 改成 tep 就兼容了 专才和留学 根据路由名判断是否走以下逻辑
        if (from.name.indexOf("tep") != -1 && this.count > 1 && this.$store.state.stepChange == 1) {
            const h = this.$createElement;
            this.$msgbox({
                    title: "提示",
                    message: h("div", null, [
                        h("span", "系统检测到当前"),
                        h("span", {
                            style: "color: red"
                        }, "内容已发生变更"),
                        h("p", null, "如需变更请点击页面下方“下一步” "),
                    ]),
                    showCancelButton: true,
                    confirmButtonText: "不变更跳转",
                    cancelButtonText: "返回",
                    distinguishCancelAndClose: true,
                    closeOnClickModal: false,
                    beforeClose: (action, instance, done) => {
                        console.log(instance.confirmButtonLoading, "start")
                        instance.confirmButtonLoading = true;
                        instance.confirmButtonText = "执行中...";
                        instance.confirmButtonLoading = false;
                        done();
                        next(action === "confirm")
                    },
                    callback(_action, instance, _done) {
                        instance.confirmButtonLoading = false;
                    }
                })
            // this.leave = to;
        } else {
            next();
        }
    },
    methods: {
        // 保存最新的步骤
        saveUserData(num) {
            console.log('进来咯~', num)
            //num 需要存在缓存里给判断step是否点击的 当前第几步
            let user = JSON.parse(localStorage.getItem("user"));
            console.log(user.baseSchedule)
            if (num > user.baseSchedule) {
                user.baseSchedule = num; //个人信息填写到第几步
                localStorage.setItem("user", JSON.stringify(user));
                localStorage.setItem("isList", 0);
                num == 7 ?
                    localStorage.setItem("stepEnd", 1) :
                    localStorage.setItem("stepEnd", 0);
                this.$store.commit("user");
            }
        }
    }

})

// 挂载一些公共方法
Vue.prototype.$rules = rules
Vue.prototype.$utils = utils
// 定义全局的filter
Object.keys(filters).forEach(key => Vue.filter(key, filters[key]));
Object.assign(filters); // 为了在js中调用filter

// 获取osskey
//utils.getOssKey(Vue)

Vue.prototype.$CONFIG = config;
console.log(Vue.options.components)

if (process.env.VUE_APP_ENV == 'production') {
    initSentry(Vue, router);
}

/* eslint-disable */
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app");
