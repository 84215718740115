<!--
 * @Author: filename
 * @Description: 移动端-轻提示toast
 * @使用：this.$toastTip.success('成功!')，this.$toastTip.fail('失败!'), this.$toastTip.message({icon: 'element的图标class', content: 'XXX'})
-->

<template>
    <div ref="toast" class="toast">
        <div class="tip">
            <div class="icon">
                <i :class="icon"/>
            </div>
            <div class="content">{{ content }}</div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        content: {
            type: String,
            default: '提交成功'
        },
        duration: {
            type: Number,
            default: 3000
        },
        icon: {
            type: String,
            default: 'el-icon-check'
        }
    },
    data() {
        return {

        }
    },
    mounted() {
        setTimeout(() => {
            this.$refs.toast.remove()
        }, this.duration)
    },
    methods: {

    }
}
</script>

<style lang="less" scoped>
.toast {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1;
    top: 0;
    left: 0;
    .tip {
        max-width: 200px;
        padding: 10px 20px;
        background: rgba(0, 0, 0, 0.7);
        position: fixed;
        top: 45%;
        left: 50%;
        overflow: hidden;
        font-size: 10px;
        border-radius: 10px;
        transform: translate3d(-50%, -50%, 0);
        transition: 0.3s;
        transition-property: transform, opacity, -webkit-transform;
        color: #fff;
    }
    .icon {
        text-align: center;
        i {
           font-size: 50px; 
        }
    }
    .content {
        text-align: center;
        font-size: 14px;
    }
}
</style>