/**
 * @description 全局判断是否是图片
 * @author Jeff.Guo
 * @example <el-button type="text" v-isImg="path">预览</el-button>
 */
const isImg = {
    inserted: (el, binding) => {
        let pathArr = binding.value.split('.');
        let fileType =  pathArr[pathArr.length - 1];
        if(!/(png|jpg|jpeg|gif)/i.test(fileType)){
            el.remove()
        }
    }
};
export default isImg