<!--
 * @Author:Jeff.Guo
 * @Description: 404错误页面
-->
<template>
    <div class="">
        404 页面不存在  
    </div>
</template>

<script lang="javascript">
    export default {
        name: "",
        data() {
            return {
                
            };
        },
        created() {

        },
    methods: {

    }
}
</script>
