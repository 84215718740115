/**
 * @description 全局复制指令
 * @author Jeff.Guo
 * @example <el-button type="text" v-copy="'要复制的内容'">复制链接</el-button>
 */

import { Message } from 'element-ui'

const vCopy = {

    bind(el, { value }) {
        el.$value = value;
        el.handler = () => {
            if (!el.$value) {
                return Message({
                    message: '复制内容不能为空！',
                    type: 'error',
                    duration: 3000
                })
            }
            const textarea = document.createElement('textarea');
            textarea.readOnly = 'readonly';
            textarea.style.position = 'absolute';
            textarea.style.left = '-9999px';
            // 将要 copy 的值赋给 textarea 标签的 value 属性
            textarea.value = el.$value;
            // 将 textarea 插入到 body 中
            document.body.appendChild(textarea);
            // 选中值并复制
            textarea.select();
            textarea.setSelectionRange(0, textarea.value.length);
            const result = document.execCommand('Copy');
            if (result) {
                Message({
                    message: '复制成功！',
                    type: 'success',
                    duration: 3000
                })
            }
            document.body.removeChild(textarea);
        };
        // 绑定点击事件
        el.addEventListener('click', el.handler);
    },
    // 当传进来的值更新的时候触发
    componentUpdated(el, { value }) {
        el.$value = value;
    },
    // 指令与元素解绑的时候，移除事件绑定
    unbind(el) {
        el.removeEventListener('click', el.handler);
    },
};
export default vCopy